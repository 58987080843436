<template>
  <div class="costs">
    <TableBlock class="costs__table" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    TableBlock: () => ({
      component: import("./table"),
    }),
  },
  computed: {
    ...mapState(["analytics"]),
  },
  mounted() {
    if(!this.analytics)
      this.$store.dispatch("getAnalytics");
  },
};
</script>

<style scoped>
.costs__block {
  margin-top: 20px;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
}
.costs__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75px;
}
.costs__items:not(:last-child) {
  margin-bottom: 32px;
}
.costs__number {
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  color: #306cdc;
  margin-bottom: 10px;
}
.costs__text {
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #707070;
  text-align: center;
}
.costs__table {
  margin-top: 20px;
}
</style>
